
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class SsoUserPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  isLoading: boolean = false;
  items: Array<any> = [];
  search: string = "";

  @Watch('search')
  onSearchChange(newSearch: string, oldSearch: string) {
    if (newSearch !== oldSearch)
      this.searchItems();
  }

  timeoutId = undefined;

  searchItems() {
    clearTimeout(this.timeoutId);
    // @ts-ignore
    this.timeoutId = setTimeout(async () => {
      await this.load();
    }, 500);
  }

  userInput() {
    this.$emit('email', this.items.find(item => item.id == this.localValue).email);
    this.input();
  }

  async load() {
    this.isLoading = true;
    try {
      if (!this.search)
        this.items = [];
      else {
        let res = await this.$http.get(this.$store.state.ssoUri + 'api/search-user?email=' + this.search);
        this.items = res;
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false;
    }
  }
}
