
import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IletisimForm from "@/components/forms/IletisimForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {
    FormDialog,
    IletisimForm,
    DeleteDialog,
  }
})
export default class IletisimList extends Mixins(SimpleInputMixin) {

  @Prop({}) action !: string;
  @Prop() parentAuthenticateName !: string;
  @Prop()height!:string
  @Prop()footerProps!:any
  localValue!: Array<IletisimBilgisiEntity>;
  iconName = 'mdi-trash-can-outline'
  color="#959595"
  headers= [
    {text: "Tür", align: "start", sortable: false, value: "iletisim_turu.isim", class: 'my-header-style'},
    {text: "No", align: "start", sortable: false, value: "deger", class: 'my-header-style'},
    {text: "İşlemler", align: "end", sortable: false, value: "actions", class: 'my-act-style'},

  ]
  load() {
    this.$emit("load");
  }

  isAuthorized(keySuffix: string) {
    let ap = [...this.$store.state.activeProcesses].reverse();
    let authKey = null;

    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;

    if (ap[0].key === "borclu-detay")
      authKey = "borclu-iletisim-bilgisi" + keySuffix;
    else if (ap[0].key === "alacakli-detay")
      authKey = "muvekkil-iletisim-bilgisi" + keySuffix;
    else if (ap[0].key === "buro-detay")
      authKey = "buro-iletisim-bilgisi" + keySuffix;
    else if (ap[0].key === "personel-detay")
      authKey = "calisan-iletisim-bilgisi" + keySuffix;
    else if (ap[0].key === "muvekkil-detay")
      authKey = "muvekkil-iletisim-bilgisi" + keySuffix;
    else if (ap[0].key === "avukat-detay")
      authKey = "avukat-iletisim-bilgisi" + keySuffix;

    if (!authKey) return false;
    return this.$helper.isAuthorized(authKey)
  }

}
